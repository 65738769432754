import React, { useState } from "react"
import Layout from "../components/layout";
import Title from "../components/title";
import Promo from "../components/promo";
import Accordion from "../components/accordion";
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { Link } from "gatsby";

const Faqs = ({ data, location }) => {

  const [state, setState] = useState({
    currency: 'usd',
    billing: 'monthly'
  });

  const billing = {
    'usd': {
      'monthly': '$29',
      'yearly': '$290',
      'vat': false
    },
    'gbp': {
      'monthly': '£20',
      'yearly': '£200',
      'vat': true
    }
  }

  const toggleCurrency = () => {
    setState({
      ...state,
      currency: (state.currency === 'gbp' ? 'usd' : 'gbp')
    });
  };

  const toggleBilling = (type) => {
    setState({
      ...state,
      billing: type
    });
  };

  return (

    <Layout 
      path={location.pathname}>

    <Helmet
        bodyAttributes={{
          class: 'bg-gray-100 text-root'
        }} />

    <div className="container max-w-6xl mx-auto">

      <Title
        title="FAQs"
        width="max-w-xs md:max-w-md lg:max-w-lg">

<p className="max-w-md leading-relaxed mx-auto mt-8 mb-12 text-lg md:text-xl"> 
If you can't find an answer that you're looking for, feel free to drop us a line.
        </p>

        <Link to="https://app.heyslate.com/register" className="btn hover:bg-gray-400 hover:border-gray-400 hover:text-root">
          <span clasName="text-accent">Contact us</span>
        </Link>

      </Title>

    </div>

    <div className="container max-w-4xl mx-auto">

      <div className="accordion__list rounded-xl border border-gray-300 space-y-px bg-gray-300 overflow-hidden">

        { data.prismic.allFaqs.edges.map((item, index) => (
          <Accordion
            key={index}
            title={item.node.title}
            content={item.node.text}/>
        ))}

      </div>

    </div>

  </Layout>
)}

export const query = graphql`
  {
    prismic {
      allFaqs {
        edges {
          node {
            title
            text
          }
        }
      }
    }
  }
`

export default Faqs;